import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { compose } from "recompose";

import { Link } from 'react-router-dom';

import { Auth } from "aws-amplify";

import ToggleMenu from "../common/toggleMenu/toggle-menu";

import axios from 'axios';

import { Loader } from "../common/Loader/loader";

import { FormValidator } from "../common/FormValidator/FormValidator";

import { store } from 'react-notifications-component';

import { API_SERVER, ODOO_API_SERVER } from '../constant';

import backImg from "../../assets/images/back.png";

import { updateThemeObject, resetThemeObject } from "../../actions/ThemeAction";
import LotsTable from './LotsTable';
import {getUserFileUploadToken, checkUserSession} from '../common/Helper/helper';

function Credits(props) {

  let [Loading, setLoading] = useState(false);
  let [expand, setExpanded] = useState(false);
  let [userEmail, setUserEmail] = useState(null);
  let [userOdooID, setUserOdooID] = useState(null);
  let [submit, setSubmit] = useState(false);
  const [userLotsList, setUserLotsList] = useState([]);

  const validator = new FormValidator([
    {
      field: 'credit',
      method: 'isEmpty',
      validWhen: false,
      message: 'User Name is required.'
    }
  ]);


  let [state, setState] = useState({
    user: {
      userId: '',
      credit: '',
      credit_limit: '',
      debit_limit: '',
      debit: '',
      validation: validator.valid()
    }
  }
  );

  function resetForm() {
    const { user } = state;
    setState({
      user: {
        ...user,
        name: '',
        validation: validator.valid()
      }
    })
    setSubmit(submit = false);
  };

  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    const { user } = state;
    setState({
      user: {
        ...user,
        [name]: value
      }
    })
  };


  let validation = validator.validate(state.user);

  async function handleSubmit(e) {
    e.preventDefault();
    validation = validator.validate(state.user);

    setSubmit(submit = true);

    if (validation.isValid) {
      setLoading(true);
      updateOdooProfile();
    }
  };

  function toggleSidebar() {

  };



  async function handleUpdate(e) {
    e.preventDefault();

  };

  async function updateOdooProfile() {
    axios.post(ODOO_API_SERVER + `update`,
      {
        "config": "prestocharts-inc",
        "module": "user",
        "id": state.user.userId,
        "input": {
          "credit": state.user.credit, "credit_limit": state.user.credit_limit,
          "debit_limit": state.user.debit_limit, "debit": state.user.debit,
        }
      }
    )
      .then(function (response) {
        if (response.status === 200) {
          store.addNotification({
            title: "Done!",
            message: "Odoo Profile Updated Successfully",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "zoomIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
              showIcon: true
            }
          });
        }
      })
      .catch(function (error) {

      })
      .then(function () {
        setLoading(false);
      });
  };


  async function getUserOdooProfileData() {
    axios.post(ODOO_API_SERVER + `getlist`,
      {
        "config": "prestocharts-inc",
        "module": "user",
        "field": "email",
        "operator": "=",
        "value": userEmail,
        "input": {
          "fields": ["name", "display_name", "email", "credit", "credit_limit",
            "debit", "debit_limit"
          ]
        }
      }
    )
      .then(function (response) {
        if (response.status === 200) {
          let userData = response.data.customer_data[0];
          console.log(userData)
          const { user } = state;
          setState({
            user: {
              ...user,
              userId: userData.id,
              credit: userData.credit,
              credit_limit: userData.credit_limit,
              debit: userData.debit,
              debit_limit: userData.debit_limit,
            }
          })

        }
        else {

        }
      })
      .catch(function (error) {

      })
      .then(function () {
        setLoading(false);
      });
  };

  async function getUserDetails() {
    const user = await Auth.currentUserInfo();
    if (user != null) {
      setUserEmail(userEmail = user.attributes.email);
      getUserOdooProfileData();
    } else {
      setLoading(false);
    }
  };
  async function fetchLotsData() {
    let {lotsList} = await getUserFileUploadToken();
    console.log("Credits", lotsList);
    if(lotsList){
      setUserLotsList(lotsList);
    }
  }
  useEffect(() => {
    setLoading(true);
    getUserDetails();

  }, []);
  useEffect(() => {
    checkUserSession();
    fetchLotsData()

  }, []);

  return (
    <React.Fragment>
      <div className="page-content inset-profile">
        <div className="userProfileForm">
          <form id="Login">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="oneInp1">
                  <label className="custom-field two inp1">
                    <input type="text" name="credit" value={state.user.credit} onChange={handleChange} placeholder="&nbsp;" />
                    <span className="placeholder">Credit</span>
                  </label>
                </div>
                <div className="oneInp1">
                  <label className="custom-field two inp2">
                    <input type="text" name="credit_limit" value={state.user.credit_limit} onChange={handleChange} placeholder="&nbsp;" />
                    <span className="placeholder">Credit Limit</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="oneInp1 phonePanel">
                  <label className="custom-field two inp-phone">
                    <input type="text" name="debit" value={state.user.debit} onChange={handleChange} placeholder="&nbsp;" />
                    <span className="placeholder">Debit</span>
                  </label>
                </div>
                <div className="oneInp1 mobilepanel">
                  <label className="custom-field two inp-mobile">
                    <input type="text" name="debit_limit" value={state.user.debit_limit} onChange={handleChange} placeholder="&nbsp;" />
                    <span className="placeholder">Debit Limit</span>
                  </label>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 save-btn-row update-pro">
                <button type="button" className="btn yellow-btn" onClick={handleSubmit} >Update</button>
              </div>
            </div>
          </form>
        </div>
        {Loading &&
          <Loader />
        }
        <div className="container">
          <h2 className="h2-page-title">Lots List</h2>
          <LotsTable userLotsList={userLotsList}/>
        </div>
      </div>
    </React.Fragment>
  )
};


const mapStateToProps = reducerObj => {
  const themeObject = reducerObj.themeReducers.themeObject;
  return { themeObject };
};

export default compose(
  connect(mapStateToProps, {
    updateThemeObject, resetThemeObject
  }),
)(Credits);
