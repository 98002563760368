import React from 'react';
import './lots.css';

const LotsList = ({ userLotsList}) => {
  return (
    <div className="lots-list">
      <div className="lots-list-header">
        <div className="lots-column">Product Name</div>
        <div className="lots-column">Token Quantity</div>
        <div className="lots-column">Purchase Date</div>
        <div className="lots-column">Token Expire On</div>
        <div className="lots-column">Action</div>
      </div>
      {userLotsList.map((lot, index) => (
        <div className="lots-list-item" key={index}>
          <div className="lots-column L-H-34">{lot.Product_name}</div>
          <div className="lots-column L-H-34">{lot.LOT_quantity}</div>
          <div className="lots-column L-H-34">{lot.LOT_Local_Time}</div>
          <div className="lots-column L-H-34">{lot.LOT_UTC_Expiration}</div>
          <div className="lots-column">
            {/* <button className="btn blue-btn" onClick={() => onDelete(invoice.id)}>Print</button>
            <button className="btn blue-btn" onClick={() => onDelete(invoice.id)}>Download</button> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LotsList;
