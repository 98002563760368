import React, { useState, useEffect } from 'react';
import InvoiceTable from './InvoiceTable';
import {getUserOutputFileData} from '../common/Helper/helper';

const App = () => {

  
  const [invoices, setInvoices] = useState([
    { id: 1, name: 'Invoice 001', date: '2024-01-01' },
    { id: 2, name: 'Invoice 002', date: '2024-02-15' },
    // Add more invoices as needed
  ]);

  useEffect(() =>{
    const invoiceData = getUserOutputFileData('invoice');
    console.log("invoiceData",invoiceData);
  }
  );
  const handleDelete = (id) => {
    setInvoices(invoices.filter(invoice => invoice.id !== id));
  };

  return (
    <div className="container">
      <h2 className="h2-page-title">Invoice List</h2>
      <InvoiceTable invoices={invoices} onDelete={handleDelete} />
    </div>
  );
};

export default App;
