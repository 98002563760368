import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';

import { Auth } from 'aws-amplify';

import axios from 'axios';

import Header from '../common/Header/outerHeader';

import { Loader } from '../common/Loader/loader';

import { history } from '../common/history/history';

import { store } from 'react-notifications-component';

import { API_SERVER } from '../constant';

import ReportModal from './ReportModal';

import { updateIndexObject } from '../../actions/IndexAction';
import { updateReportObject } from '../../actions/ReportAction';
import { updateTabObject, resetTabObject } from '../../actions/TabAction';
import {
  updateLanguageObject,
  resetLanguageObject,
} from '../../actions/TranslationAction';

import './MainPage.css';

import Home from '../HomeTab/OuterHome';

import Footer from '../common/Footer/footer';

function MainPage(props) {
  const { t, i18n } = useTranslation('common');
  let [Loading, setLoading] = useState(false);
  let [expand, setExpanded] = useState(false);
  let [tabIndex, setTabIndex] = useState(0);

  async function resetChartSettings() {
    props.resetEChartDataObject();
  }

  function tabChange(index, lastIndex) {
    props.updateTabObject({ tabIndex: index });
  }

  async function onLoad() {
    try {
      let result = await Auth.currentSession();
      history.push('/home');
    } catch (e) {}
  }

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    setTabIndex(props.tabObject.tabIndex);
  }, [props.tabObject.tabIndex]);

  useEffect(() => {
    i18n.changeLanguage(props.languageObject.currentLanguage);
  }, [props.languageObject.currentLanguage]);

  return (
    <React.Fragment>
      <Header />
      <div>
        <div className='navTabList'>
          <div>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index, lastIndex) => tabChange(index, lastIndex)}
            >
              {/* <TabList className='navbarpanel'>
                <Tab>{t('Header.home')}</Tab>
                <Tab>{t('Header.fileupload')}</Tab>
                <Tab>{t('Header.chartIt')}</Tab>
                <Tab>{t('Header.dashboard')}</Tab>
                <Tab>{t('Header.collection')}</Tab>
                <Tab>{t('Header.pivot')}</Tab>
                <Tab>{t('Header.presentation')}</Tab>
                <Tab>{t('Header.about')}</Tab>
              </TabList> */}
              <TabPanel>
                <Home />
              </TabPanel>
              <TabPanel>
                <Home />
              </TabPanel>
              <TabPanel>
                <Home />
              </TabPanel>
              <TabPanel>
                <Home />
              </TabPanel>
              <TabPanel>
                <Home />
              </TabPanel>
              <TabPanel>
                <Home />
              </TabPanel>
              <TabPanel>
                <Home />
              </TabPanel>
              <TabPanel>
                <Home />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

const mapStateToProps = (reducerObj) => {
  const tabObject = reducerObj.tabReducers.tabObject;
  const languageObject = reducerObj.translationReducers.langObject;
  return { languageObject, tabObject };
};

export default compose(
  connect(mapStateToProps, {
    updateTabObject,
    resetTabObject,
    updateLanguageObject,
    resetLanguageObject,
  })
)(MainPage);
