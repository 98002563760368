import React from 'react';
import './invoice.css';

const InvoiceList = ({ invoices, onDelete }) => {
  return (
    <div className="invoice-list">
      <div className="invoice-list-header">
        <div className="invoice-column">Invoice Name</div>
        <div className="invoice-column">Create Date</div>
        <div className="invoice-column">Action</div>
      </div>
      {invoices.map((invoice, index) => (
        <div className="invoice-list-item" key={index}>
          <div className="invoice-column L-H-34">{invoice.name}</div>
          <div className="invoice-column L-H-34">{invoice.date}</div>
          <div className="invoice-column">
            <button className="btn blue-btn" onClick={() => onDelete(invoice.id)}>Print</button>
            <button className="btn blue-btn" onClick={() => onDelete(invoice.id)}>Download</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InvoiceList;
