import React, { useState , useEffect , useRef }  from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useTranslation} from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ToggleMenu from "../common/toggleMenu/toggle-menu";
import Header from '../common/Header/innerHeader';

import { Link } from 'react-router-dom';

import Profile  from "./Profile";
import Payment  from "./Payment";
import Credit  from "./Credits";
import Invoice from "./Invoice";

import backImg   from "../../assets/images/back.png";

import "./MainPage.css";

function MainPage(props) {  
  const [collapsed, setCollapsed] = useState(false);
  const [selectedItem, setSelectedItem] = useState('1');

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuItemClick = (item) => {
    alert(item.key)
    setSelectedItem(item.key);
  };
      const {t, i18n} = useTranslation('common');
      
      let [tabIndex, setTabIndex] = useState(0);
       const [operations, setOperations] = useState([]);
      let [expand, setExpanded] = useState(false);

      let { active_tab } = useParams();
      console.log("active_tab",active_tab)
      function tabChange(index ,lastIndex)
      {
         setTabIndex(index);
      };

      function toggleSidebar()
      {

      };

      function handleSave(img)
      {
        console.log(img.dataUrl);
      }

      const handleLogo = () =>{
        window.location.replace("/home");
      }

    return(
        <React.Fragment>
          <div id="wrapper" className={expand ? "menu-active" : "menu"}>
          <Header 
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          handleMenuItemClick={handleMenuItemClick}
          
          />
                <div id="page-content-wrapper" className="page-content-wrapper-profile">
                  {/*<div className="navbarpanel"><Link data-tip data-for="dashboard"  to="/home">
                    <img className="prof-profile" src={backImg}/></Link>
                  </div>*/}
                  {/* <Tabs selectedIndex={tabIndex} onSelect={(index,lastIndex) => tabChange(index,lastIndex)}>
                       <TabList>
                          
                          <Tab> {t('Tab.editProfile')}</Tab>
                          <Tab>{t('Tab.manageCredits')}</Tab>
                          <Tab> {t('Tab.subscription')}</Tab>
                          <Tab>{t('Tab.payments')}</Tab>
                        </TabList>
                        <TabPanel>
                           <Profile/>
                        </TabPanel>
                        <TabPanel>
                           <Credit/>
                        </TabPanel>
                        <TabPanel>
                            <p>TAB3</p>
                        </TabPanel>
                        <TabPanel>
                            <Payment/>
                        </TabPanel>
                  </Tabs> */}
                  {(active_tab==='editprofile' || active_tab==null) ?
                  <Profile/>
                  : (active_tab==='managecredits')?
                  <Credit/>
                  :(active_tab==='payments')?
                  <Payment/>
                  :(active_tab==='invoice')?
                  <Invoice/>
                  :null
                  } 
                </div>
              </div>
         </React.Fragment>
       )
};


const mapStateToProps = reducerObj => {
  const themeObject = reducerObj.themeReducers.themeObject;
    const languageObject = reducerObj.translationReducers.langObject;
  return { themeObject ,languageObject };
};

export default compose(
  connect(mapStateToProps, {

  }),
)(MainPage);
