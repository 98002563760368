import React, { useState, useEffect, useCallback, useRef } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

// Renders errors or successful transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

function Paypal(props) {
  const { productId, productQty, productprice, paypalResponse, setPaypalResponse } = props;
  const initialOptions = {
    "client-id": "ATo_lFql0IXQZi3EQrOu_CH71JX-6wx3Gqr7mRNjPCvCk8ECXiz-KxHIl0POZ7TJGi0cJjuo0QgbYErn",
    "enable-funding": "card",
    "disable-funding": "paylater,venmo",
    "data-sdk-integration-source": "integrationbuilder_sc",    
  };

  const [message, setMessage] = useState("");
  const createOrderHandlerRef = useRef(null);
  
  console.log("Inside paypal props are: ", props, productId, productQty, productprice);
  useEffect(() => {
    // Log props whenever they change
    console.log("Props changed:", props);
  }, [props]);

  // Memoize createOrderHandler using useCallback
  useEffect(() => {
    createOrderHandlerRef.current = async (data, actions) => {
      try {
        console.log("Inside createOrderHandler props are: ", productId, productQty, productprice);
        const response = await fetch(`${process.env.REACT_APP_PAYPAL_URL}/api/orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cart: [
              {
                id: productId,
                quantity: productQty,
                productprice: productprice,
                currency_code:"USD"
              },
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            }
          }),
        });
    
        const orderData = await response.json();
    
        if (orderData.id) {
          return orderData.id;
        } else {
          const errorDetail = orderData?.details?.[0];
          const errorMessage = errorDetail
            ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
            : JSON.stringify(orderData);
    
          throw new Error(errorMessage);
        }
      } catch (error) {
        console.error(error);
        setMessage(`Could not initiate PayPal Checkout...${error}`);
      }
    };
  }, [productId, productQty]);

  // // Memoize createOrderHandler using useCallback
  // const createOrderHandler = useCallback(async (data, actions) => {
  //   try {
  //     console.log("Inside createOrderHandler props are: ", productId, productQty);
  //     // Your createOrderHandler logic
  //   } catch (error) {
  //     console.error(error);
  //     setMessage(`Could not initiate PayPal Checkout...${error}`);
  //   }
  // }, [productId, productQty]);
  
  return (
    <div className="App">
      <PayPalScriptProvider options={initialOptions} >      
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={(data, actions) => createOrderHandlerRef.current(data, actions)}
          onApprove={async (data, actions) => {
            
            try {
              const response = await fetch(
                `${process.env.REACT_APP_PAYPAL_URL}/api/orders/${data.orderID}/capture`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              );

              const orderData = await response.json();
              setPaypalResponse(orderData);
              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
              } else if (errorDetail) {
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                const transaction =
                  orderData.purchase_units[0].payments.captures[0];
                setMessage(
                  `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                );
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
              }
            } catch (error) {
              console.error(error);
              setMessage(
                `Sorry, your transaction could not be processed...${error}`,
              );
            }
          }}
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>
  );
}

export default Paypal;
