import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { PropTypes } from "prop-types";

import { compose } from "recompose";

import { Link } from 'react-router-dom';

import ReactTooltip from "react-tooltip";

import { useIdleTimer } from 'react-idle-timer';

import Switch from "react-switch";

import { Auth } from "aws-amplify";

import { useTranslation } from "react-i18next";

import './outerHeader.css';

import { history } from '../history/history';

import { updateLanguageObject, resetLanguageObject } from "../../../actions/TranslationAction";
import { updateToolTipObject, resetToolTipObject } from "../../../actions/ToolTipAction";

import ConfirmationAlertModal from "./ConfirmationAlertModal";

import TranslationToggle from "./translationToggle";

import old_logo from "../../../assets/images/old_logo.png";

import new_logo from "../../../assets/images/logo.png";

import user_profile from "../../../assets/images/user_profile.png";

import logout from "../../../assets/images/logout.png";

import SignUpModal from "./SignUpModal";
import MainMenu from "./mainMenuOuter";

const OuterHeader = (props) => {
  const { items, collapsed, toggleCollapsed, handleMenuItemClick } = props;
  const { t, i18n } = useTranslation('common');
  let [showConfirmPopup, setShowConfirmPopup] = useState(false);
  let [switchChecked, setSwitchChecked] = useState(false);
  let [menuTitle, setMenuTitle] = useState("");
  let [showSignUpPopup, setShowSignUpPopup] = useState(false);
  let [selectedItem, setSelectedItem] = useState(1);
  console.log("selectedItem", selectedItem);

  useEffect(() => {
    if (selectedItem == 2) {      
      setMenuTitle({ title: t('Header.fileupload-signupPopup'), promotionText: "Get 10 MB of free file processing" });
    } else if (selectedItem == 3) {      
      setMenuTitle({ title: t('Header.editChart-signupPopup'), promotionText: "Get 10 MB of free file processing" });
    } else if (selectedItem == 4) {      
      setMenuTitle({ title: t('Header.editChart-signupPopup'), promotionText: "Get 10 MB of free file processing" });
    } else if (selectedItem == 5) {      
      setMenuTitle({ title: t('Header.editChart-signupPopup'), promotionText: "Get 10 MB of free file processing" });
    } else if (selectedItem == 6) {      
      setMenuTitle({ title: t('Header.editChart-signupPopup'), promotionText: "Get 10 MB of free file processing" });
    } else if (selectedItem == 7) {      
      setMenuTitle({ title: t('Header.editChart-signupPopup'), promotionText: "" });
    } else {      
      setMenuTitle({ title: "", promotionText: "" });
    } 

  }, [selectedItem]);
  const openSignUpPopup = () => {
    setMenuTitle({ title: "", promotionText: "" })
    setShowSignUpPopup(showSignUpPopup = true);
  };

  const closeSignUpPopup = () => {
    setShowSignUpPopup(showSignUpPopup = false);
  };

  async function logOut() {
    await Auth.signOut();
  };

  const openConfirmPopup = () => {
    console.log("test")
    setShowConfirmPopup(showConfirmPopup = true);
  };

  const closeConfirmPopup = () => {
    setShowConfirmPopup(showConfirmPopup = false);
  };

  const onConfirmModalCloseAfterApi = (response) => {
    if (response === "true") {
      localStorage.removeItem("loginStatus");
      logOut();
      history.push('/');
    } else {
      setShowConfirmPopup(showConfirmPopup = false);
    }
  };

  const handleOnIdle = event => {
    logOut();
  };

  const handleOnActive = event => {
  };

  const handleOnAction = (e) => {
  };

  function handleChange(checked) {
    setSwitchChecked(switchChecked = !checked);
    props.updateToolTipObject({ toolTip: switchChecked });
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });

  return (
    <React.Fragment>
      <SignUpModal
        showSignUpPopup={showSignUpPopup}
        closeSignUpPopup={closeSignUpPopup}
        menuTitle={menuTitle}
        setMenuTitle={setMenuTitle}
      />
      <header>
        <div className="top-header d-flex align-items-center">
          <div className="container">
            <div className="top-wrap d-flex align-items-center">
              <div className="left-logo">
                <div className="main-menu">
                  <MainMenu
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    showSignUpPopup={showSignUpPopup}
                    setShowSignUpPopup={setShowSignUpPopup}
                    items={items}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                    handleMenuItemClick={handleMenuItemClick}
                  /></div>
                <img src={new_logo} alt="logo" />
              </div>
              <div className="right-box">
                <ul className="nav-right">
                  <li><TranslationToggle /></li>
                  <li>
                    {
                      props.toolTipObject.toolTip ?
                        <div className="switch-btn">
                          <label>
                            <span data-tip data-for="enabletooltip"></span>
                            <Switch onChange={handleChange}
                              checked={!switchChecked}
                              onColor="#0000FF"
                              onHandleColor="#2693e6"
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={20}
                              width={48}
                              className="react-switch"
                              id="material-switch"
                            />
                          </label>
                        </div>
                        : <div className="switch-btn" data-tip data-for="disabletooltip"><label>
                          <span></span>
                          <Switch onChange={handleChange}
                            checked={!switchChecked}
                            onColor="#0000FF"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="material-switch"
                          />
                        </label>
                        </div>
                    }
                  </li>

                  <li><div className="topButtonSignIn"><button className="signinbtn" onClick={(e) => openSignUpPopup()}>Sign In</button></div></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip id="profile" disable={props.toolTipObject.toolTip} place="left" delayHide={100} effect="float">
          {t('Tooltip.profile')}
        </ReactTooltip>
        <ReactTooltip id="profile" disable={props.toolTipObject.toolTip} place="left" delayHide={100} effect="float">
          {t('Tooltip.profile')}
        </ReactTooltip>

        <ReactTooltip id="logout" disable={props.toolTipObject.toolTip} place="right" delayHide={100} effect="float">
          {t('Tooltip.logout')}
        </ReactTooltip>

        <ReactTooltip id="translate" disable={props.toolTipObject.toolTip} place="left" delayHide={100} effect="float">
          {t('Tooltip.language')}
        </ReactTooltip>


        <ReactTooltip id="disabletooltip" place="right" delayHide={100} effect="float">
          {props.toolTipObject.toolTip ? t('Tooltip.enabletooltip') : t('Tooltip.disabletooltip')}
        </ReactTooltip>

        <ReactTooltip id="enabletooltip" place="right" delayHide={100} effect="float">
          {t('Tooltip.enabletooltip')}
        </ReactTooltip>

      </header>
    </React.Fragment>
  )
};



const mapStateToProps = reducerObj => {
  const languageObject = reducerObj.translationReducers.langObject;
  const toolTipObject = reducerObj.toolTipReducers.toolTipObject;
  return { languageObject, toolTipObject };
};

export default compose(
  connect(mapStateToProps, {
    updateLanguageObject, resetLanguageObject,
    updateToolTipObject, resetToolTipObject
  }),
)(OuterHeader);
